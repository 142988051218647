@import '~bootstrap/dist/css/bootstrap.min.css';

@font-face {
    font-family: 'lato';
    src: url('./fonts/lato-regular.woff2') format('woff2'),
         url('./fonts/lato-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'lato';
    src:  url('./fonts/lato-bold.woff2') format('woff2'),
          url('./fonts/lato-bold.woff') format('woff'),
          url('./fonts/lato-bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


.modal-header .close > span{
   font-size: 30px;
}


html, body {
    height: 100%;
}