#arrow,
#arrow::after {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
}

#arrow::after {
    content: '';
    transform: rotate(45deg);
    background: white;
}

#color-tooltip[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
}

#color-tooltip[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
}

#color-tooltip[data-popper-placement^='left'] > #arrow {
    right: -4px;
}

#color-tooltip[data-popper-placement^='right'] > #arrow {
    left: -4px;
}
